import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import moment from "moment";
import { useSelector } from "react-redux";
import AdminSidebar from "../../layouts/AdminSidebar";
import AppConstants from "../../../appConstants";
import webServices from "../../../services/webServices";
import { authCall } from "../../../services";
import { Bar, Line } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import ChartPopUp from "../ChartPopUp";
import RestaurantUserProfilePopup from "../restaurant/RestaurantUserProfilePopup";

// Register necessary components with ChartJS
ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  LineElement,
  PointElement,
  Title,
  Tooltip,
  Legend
);

const options = {
  responsive: true,
  plugins: {
    legend: {
      display: false,
    },
  },
  scales: {
    y: {
      beginAtZero: true,
    },
  },
  layout: {},
};

// const startDate = moment().subtract(6, "days").format("YYYY-MM-DD");
const startDateDay = moment().subtract(27, "days").format("YYYY-MM-DD");
const startDateDayOfWeek = moment().subtract(30, "days").format("YYYY-MM-DD");
const startDateHour = moment().subtract(30, "days").format("YYYY-MM-DD");
const endDate = moment().format("YYYY-MM-DD");

function HotelDashboard() {
  const { current } = useSelector((state) => state.user);
  const [touchLocations, setTouchLocations] = useState([]);
  const [loading, setLoading] = useState(false);
  const [title, setTitle] = useState("");
  const [chartType, setChartType] = useState("");
  const [chartData, setChartData] = useState({});
  const [chartOptions, setChartOptions] = useState({});
  const [showUserProfile, setShowUserProfile] = useState(false);

  const [startDT, setStartDT] = useState(
    moment().subtract(6, "days").format("YYYY-MM-DD")
  );

  const [perDayIncome, setPerDayIncome] = useState(null);
  const [dayOfWeekIncome, setDayOfWeekIncome] = useState(null);
  const [hourIncome, setHourIncome] = useState(null);
  const [weekReveals, setWeekReveals] = useState([]);
  const [dayRevealLoading, setDayRevealLoading] = useState(false);
  const [type, setType] = useState("");

  // For Bootstrap Model
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);

  useEffect(() => {
    async function fetchData() {
      setLoading(true);

      // day wise income
      const resp2 = await authCall("getPerDayIncome", {
        startDate: startDateDay,
        endDate,
        account_id: current?.stripe_account_id,
      });
      if (resp2?.type === "success") {
        setPerDayIncome(resp2?.payload);
      }

      // day of week income
      const resp3 = await authCall("getDayOfWeekIncome", {
        startDate: startDateDayOfWeek,
        endDate,
        account_id: current?.stripe_account_id,
      });
      if (resp3?.type === "success") {
        setDayOfWeekIncome(resp3?.payload);
      }

      // per hours income
      const resp4 = await authCall("getPerHoursIncome", {
        startDate: startDateHour,
        startDate: startDateDayOfWeek,
        endDate,
        account_id: current?.stripe_account_id,
      });
      if (resp4?.type === "success") {
        setHourIncome(resp4?.payload);
      }
    }
    fetchData();
  }, []);

  const filterChart = (title, Chart, chartData, type, stdate) => {
    setTitle(title);
    setShow(true);
    setChartType(Chart);
    setChartData(chartData);
    setType(type);
    // const stdate =
    //   type === "day"
    //     ? moment().subtract(6, "days").format("YYYY-MM-DD")
    //     : moment().subtract(30, "days").format("YYYY-MM-DD");
    // setStartDT(stdate);
    setStartDT(stdate);
    console.log(stdate);
    // handleChangeFiter(stdate, endDate, type);
  };

  const handleChangeFiter = async (startDate, endDate, typ) => {
    if (typ === "day") {
      const resp = await authCall("getPerDayIncome", {
        startDate,
        endDate,
        account_id: current?.stripe_account_id,
      });
      if (resp?.type === "success") {
        setChartData(resp?.payload);
      }
    } else if (typ === "week") {
      const resp = await authCall("getDayOfWeekIncome", {
        startDate,
        endDate,
        account_id: current?.stripe_account_id,
      });
      if (resp?.type === "success") {
        setChartData(resp?.payload);
      }
    } else {
      const resp = await authCall("getPerHoursIncome", {
        startDate,
        endDate,
        account_id: current?.stripe_account_id,
      });
      if (resp?.type === "success") {
        setChartData(resp?.payload);
      }
    }
  };

  const closeUserProfile = () => {
    setShowUserProfile(false);
  };

  return (
    <>
      <div className="dashboard-page py-4">
        <div className="container custom-container">
          <div className="row">
            <div className="col-sm-4 col-lg-3 mb-4">
              <AdminSidebar />
            </div>
            <div className="col-sm-8 col-lg-9">
              <div className="common-info-card">
                <div className="heading-title lh-1">Dashboard:</div>
                <div className="details-wrapper pt-3">
                  <div className="sub-title lh-sm mb-3 mb-lg-4">
                    Below you will see how your hotel is preforming:
                  </div>

                  <div className="row pb-3">
                    <div className="col-12">
                      <div className="chart-wrapper mb-4">
                        <div className="chart-cover p-3">
                          <div className="fw-medium mb-1">Income Per Day</div>
                          {!perDayIncome && (
                            <div
                              style={{
                                height: "136px",
                                padding: "50px",
                                textAlign: "center",
                              }}
                            >
                              Loading...
                            </div>
                          )}
                          {perDayIncome && (
                            <Line data={perDayIncome} options={options} />
                          )}
                        </div>
                        <div className="btn-wrapper text-center mt-3">
                          <button
                            type="button"
                            className="btn btn-custom"
                            onClick={() =>
                              filterChart(
                                "Income Per Day",
                                Line,
                                perDayIncome,
                                "day",
                                startDateDay
                              )
                            }
                          >
                            Select
                          </button>
                        </div>
                      </div>
                      <div className="chart-wrapper mb-4">
                        <div className="chart-cover p-3">
                          <div className="fw-medium mb-1">
                            Income By Days Of The Week
                          </div>
                          {!dayOfWeekIncome && (
                            <div
                              style={{
                                height: "136px",
                                // width: "273px",
                                padding: "50px",
                                textAlign: "center",
                              }}
                            >
                              Loading...
                            </div>
                          )}
                          {dayOfWeekIncome && (
                            <Bar data={dayOfWeekIncome} options={options} />
                          )}
                        </div>
                        <div className="btn-wrapper text-center mt-3">
                          <button
                            type="button"
                            className="btn btn-custom"
                            onClick={() =>
                              filterChart(
                                "Income By Days Of The Week",
                                Bar,
                                dayOfWeekIncome,
                                "week",
                                startDateDayOfWeek
                              )
                            }
                          >
                            Select
                          </button>
                        </div>
                      </div>
                      <div className="chart-wrapper mb-4">
                        <div className="chart-cover p-3">
                          <div className="fw-medium mb-1">
                            Income By Hour Of The Day
                          </div>
                          {!hourIncome && (
                            <div
                              style={{
                                height: "136px",
                                // width: "273px",
                                padding: "50px",
                                textAlign: "center",
                              }}
                            >
                              Loading...
                            </div>
                          )}
                          {hourIncome && (
                            <Bar data={hourIncome} options={options} />
                          )}
                        </div>
                        <div className="btn-wrapper text-center mt-3">
                          <button
                            type="button"
                            className="btn btn-custom"
                            onClick={() =>
                              filterChart(
                                "Income By Hour Of The Day",
                                Bar,
                                hourIncome,
                                "hour",
                                startDateHour
                              )
                            }
                          >
                            Select
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ChartPopUp
        handleClose={handleClose}
        modalStatus={show}
        title={title}
        type={type}
        Chart={chartType}
        data={chartData}
        options={options}
        handleChangeFiter={handleChangeFiter}
        startDt={startDT}
        endDt={endDate}
      />
      <RestaurantUserProfilePopup
        show={showUserProfile}
        handleClose={closeUserProfile}
      />
    </>
  );
}

export default HotelDashboard;
