import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { Badge } from "react-bootstrap";
import moment from "moment-timezone";

import AdminSidebar from "../layouts/AdminSidebar";
import { authCall } from "../../services";

function HotelPaymentListing() {
  const { current } = useSelector((state) => state.user);
  const [paymentList, setPaymentList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [accountId, setAccountId] = useState(current?.stripe_account_id);
  console.log({ current });
  useEffect(() => {
    async function fetchData() {
      setLoading(true);
      const resp = await authCall("getMyPayments", {
        account_id: accountId, //"acct_1QvwCMPNmcJO4JET",
      });
      if (resp?.type === "success") {
        setPaymentList(resp?.payload);
        setLoading(false);
      } else {
        setPaymentList([]);
        setLoading(false);
        toast.error(resp?.errormessage);
      }
    }
    fetchData();
  }, []);

  return (
    <>
      <div className="update-machine-page common-table-design py-4">
        <div className="container custom-container">
          <div className="row">
            <div className="col-sm-4 col-lg-3 mb-4">
              <AdminSidebar />
            </div>
            <div className="col-sm-8 col-lg-9">
              <div className="common-info-card">
                <div className="heading-title">Itemized Payments:</div>
                <div className="details-wrapper py-lg-4">
                  <div className="table-responsive">
                    <table className="table table-borderless table-custom m-0">
                      <thead>
                        <tr>
                          <th scope="col">#:</th>
                          <th scope="col">Amount:</th>
                          <th scope="col">Status:</th>
                          <th scope="col">Date:</th>
                        </tr>
                      </thead>
                      <tbody>
                        {!loading &&
                          (paymentList.length > 0 ? (
                            paymentList.map((el, index) => (
                              <tr key={index}>
                                <td scope="row">#{index + 1}</td>
                                <td>
                                  {el.currency.toUpperCase()} $
                                  {(el.amount / 100).toFixed(2)}
                                </td>
                                <td className="text-center1">
                                  <Badge
                                    bg={
                                      el?.status === "succeeded"
                                        ? "success"
                                        : "danger"
                                    }
                                    className="cursor-pointer"
                                  >
                                    {el?.status}
                                  </Badge>
                                </td>
                                <td>
                                  {moment
                                    .unix(el?.created)
                                    .tz("Australia/Sydney") // Convert to AEST/AEDT
                                    .format("YYYY-MM-DD HH:mm:ss")}
                                </td>
                              </tr>
                            ))
                          ) : (
                            <tr>
                              <td colSpan={10} className="text-center">
                                No record found
                              </td>
                            </tr>
                          ))}
                        {loading && (
                          <tr>
                            <td colSpan={10} className="text-center">
                              Loading...
                            </td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default HotelPaymentListing;
