import React, { useEffect, useState } from "react";
import uploadIcon from "../../assets/images/upload-icon.svg";
import CropperModal from "./CropperModal";

const BannerImagesUploads = ({ bannerImages, images, label }) => {
  // const fileInputBannerRef = useRef(null);
  const [bannerField, setBannerField] = useState([
    { id: 1, value: "", isNew: true },
  ]);
  const [isBanner, setIsBanner] = useState(0);
  const [imageForCrop, setImageForCrop] = useState("");
  const [show, setShow] = useState(false);

  useEffect(() => {
    if(images && images.length > 0){
      setBannerField(images);
    }
  }, [images]);

  const handleBrowseClick = (id) => {
    // fileInputBannerRef.current.click();
    document.getElementById(`fileInput-${id}`).click();
    setIsBanner(id);
  };

  const onChange = (id, e) => {
    e.preventDefault();
    if (id) {
      setIsBanner(id);
    }

    let files;
    if (e.dataTransfer) {
      files = e.dataTransfer.files;
    } else if (e.target) {
      files = e.target.files;
    }
    const reader = new FileReader();
    reader.onload = () => {
      setImageForCrop(reader.result);
    };
    reader.readAsDataURL(files[0]);
    setShow(true);
  };

  const getCropData = (val) => {
    const images = bannerField.map((field) =>
      field.id === isBanner ? { ...field, value: val, isNew: true } : field
    );
    setBannerField(images);
    bannerImages(images);
  };

  const removeBannerImage = (index) => {
    const updatedFiles = bannerField.filter((_, i) => i !== index);
    setBannerField(updatedFiles);
    bannerImages(updatedFiles);
  };

  const handleClose = () => {
    setShow(false);
  };

  const addBannerField = () => {
    const newFields = [];
    const newField = { id: bannerField.length + 1, value: "", isNew: true };
    newFields.push(newField);
    setBannerField([...bannerField, ...newFields]);
  };

  return (
    <>
      <div className="banner-cover mb-2">
        {!label && (
          <>
            <div className="main-heading2 text-white mb-1">Banner Images:</div>
            <p className="fs-12">
              <i>(These images will be displayed at the top of the page)</i>
            </p>
          </>
        )}
        <div className="wrapper">
          {bannerField.map((el, index) => (
            <div key={el.id}>
              {el.value === "" && (
                <div className="custom-input-file mb-2">
                  <input
                    type="file"
                    id={`filee-${el.id}`}
                    className="inputfile"
                    accept="image/*"
                    onChange={(e) => onChange(el.id, e)}
                  />
                  <label htmlFor={`filee-${el.id}`} className="m-0">
                    <span></span>
                    <div className="icon">
                      <img src={uploadIcon} alt="upload icon" />
                    </div>
                  </label>
                </div>
              )}
              {el.value !== "" && (
                <>
                  <div style={{ width: "120px" }} className="img-wrapper">
                    <img
                      src={el.value}
                      className="img-fluid rounded"
                      alt="cropped"
                    />
                    <input
                      type="file"
                      // ref={fileInputBannerRef}
                      accept="image/*"
                      id={`fileInput-${el.id}`}
                      style={{ display: "none" }}
                      onChange={(e) => onChange("", e)}
                    />
                  </div>

                  <div className="d-flex flex-wrap align-items-center gap-2 mt-2 mb-3">
                    <button
                      type="button"
                      className="btn btn-custom btn-custom-sm"
                      onClick={() => handleBrowseClick(el.id)}
                    >
                      Change
                    </button>
                    <button
                      type="button"
                      className="btn btn-sm btn-danger"
                      onClick={() => removeBannerImage(index)}
                    >
                      <i className="bi bi-trash3-fill"></i> Remove
                    </button>
                  </div>
                </>
              )}
            </div>
          ))}
        </div>
        <div
          className="add-another fw-medium text-end mt-2"
          onClick={addBannerField}
        >
          <span className="glyphicon glyphicon-plus" aria-hidden="true"></span>{" "}
          {label ? label : "Add Another Banner Image"}
        </div>
      </div>
      <CropperModal
        show={show}
        handleClose={handleClose}
        image={imageForCrop}
        aspectRation={3/2}
        sendCropedDataToParent={getCropData}
      />
    </>
  );
};

export default BannerImagesUploads;
