import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
// import { useSelector } from "react-redux";
import { QuestionCircle } from "react-bootstrap-icons";
import VideoTutorialPopUp from "../admin/VideoTutorialPopUp";
import AppConstants from "../../appConstants";
import CATEGORIES from "../../locales/CompanyCategories.json";

const CategorySelectionVideo = AppConstants.tutorial.categorySelection;

function CompanyCategoryPage({
  SideBar,
  details,
  btnLabel,
  handleSubmit,
  loading,
  stepValue,
  setStep,
}) {
  const [videoURL, setVideoURL] = useState("");
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [otherValue, setOtherValue] = useState("");

  useEffect(() => {
    setSelectedCategories(details?.categories || []);
    setOtherValue(details?.activity_category_other || "");
  }, [details]);

  const handleCheckboxChange = (option) => {
    // setSelectedCategories(value);
    setSelectedCategories((prev) =>
      prev.some((item) => item.name === option.name)
        ? prev.filter((item) => item.name !== option.name)
        : [...prev, option]
    );
  };

  const handleSubmitForm = async (event) => {
    event.preventDefault();
    if (selectedCategories.length === 0) {
      toast.dismiss();
      toast.error("Please select category.");
    } else {
      const data = {
        bookingId: details?.id,
        // activityCategory: selectedCategories,
        categories: selectedCategories,
        profileLavel: stepValue,
        activityCategoryOther: otherValue,
      };
      handleSubmit(data, stepValue);
    }
  };

  const handleClickTutorial = () => {
    setVideoURL(CategorySelectionVideo);
  };

  const tutoralClose = () => {
    setVideoURL("");
  };

  return (
    <>
      {console.log("details", details)}
      <div className="restaurant-category-page py-4">
        <div className="container">
          <div className="row">
            <div className="col-md-4 col-lg-3 mb-4">
              <SideBar setStep={setStep} />
            </div>
            <div className="col-md-8 col-lg-9">
              <div className="common-info-card">
                <div className="heading-title d-flex flex-wrap justify-content-between gap-2">
                  <span>Which Category Best Represents Your Company?</span>
                  <span
                    className="cursor-pointer"
                    onClick={handleClickTutorial}
                  >
                    <QuestionCircle />
                  </span>
                </div>
                <div className="details-wrapper">
                  <div className="fs-18 mb-4">
                    Please select the most relevant categories from the list
                    below:
                  </div>
                  <div action="">
                    <div className="row row-cols-sm-2 row-cols-lg-3">
                      {CATEGORIES.map((cat) => {
                        return (
                          <div
                            key={cat.id}
                            className="form-group d-flex align-items-center gap-3"
                          >
                            <input
                              type="checkbox"
                              // type="radio"
                              // className="custom-radio m-0 cursor-pointer"
                              // name={cat.name}
                              name="restaurantCategories"
                              id={cat.name}
                              value={cat.label}
                              // checked={selectedCategories === cat.label}
                              checked={selectedCategories.some(
                                (item) => item.name === cat.name
                              )}
                              onChange={() => handleCheckboxChange(cat)}
                            />
                            <label
                              // className="form-check-label m-0"
                              className="m-0"
                              htmlFor={cat.name}
                            >
                              {cat.label}
                            </label>
                          </div>
                        );
                      })}
                    </div>
                    {selectedCategories.some(
                      (item) => item.name === "other"
                    ) && (
                      <input
                        type="text"
                        className="form-control form-group d-flex align-items-center gap-3"
                        style={{ width: 250 }}
                        id="companyName"
                        name="companyName"
                        placeholder="Add Category..."
                        autoComplete="off"
                        value={otherValue}
                        onChange={(e) => setOtherValue(e.target.value)}
                      />
                    )}
                    <div className="btn-wrapper text-center my-2">
                      <button
                        type="submit"
                        className="btn btn-custom"
                        onClick={handleSubmitForm}
                        disabled={loading || false}
                      >
                        {loading ? "Loading..." : btnLabel}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <VideoTutorialPopUp videoURL={videoURL} closeVideo={tutoralClose} />
    </>
  );
}

export default CompanyCategoryPage;
