import React, { useEffect, useState, useRef } from "react";
import { toast } from "react-toastify";
import { Button } from "react-bootstrap";
import SimpleReactValidator from "simple-react-validator";
import Select from "react-select";
import { State } from "country-state-city";
import { useNavigate, useLocation } from "react-router-dom";
import AdminSidebar from "../../layouts/AdminSidebar";
import { handleNumericInput } from "../../util/utils";
import { authCall } from "../../../services";
import AppConstants from "../../../appConstants";

const USERTYPE = [
  { value: "Licensee", label: "Licensee" },
  { value: "Promoter", label: "Promoter" },
  { value: "Advertiser", label: "Advertiser" },
  { value: "Designer", label: "Designer" },
  { value: "Hotel", label: "Hotel" },
];

const customStyles = {
  control: (provided) => ({
    ...provided,
    backgroundColor: "#bababa",
    border: "2px solid #fff",
  }),
  option: (provided, state) => ({
    ...provided,
    backgroundColor: state.isSelected
      ? "#007BFF"
      : state.isFocused
      ? "#e0e0e0"
      : "#fff",
    color: state.isSelected ? "#fff" : "#000",
    "&:hover": {
      backgroundColor: "#e0e0e0",
    },
  }),
  singleValue: (provided, state) => ({
    ...provided,
    color: "#000",
  }),
  menu: (provided, state) => ({
    ...provided,
    backgroundColor: "#fff",
    zIndex: 9999,
  }),
  menuList: (provided, state) => ({
    ...provided,
    backgroundColor: "#fff",
  }),
  placeholder: (provided) => ({
    ...provided,
    color: "#383636",
    // fontWeight: 600,
  }),
  dropdownIndicator: (provided) => ({
    ...provided,
    color: "#383636",
  }),
};

function UserAdd() {
  const validator = useRef(new SimpleReactValidator());
  const [, forceUpdate] = useState();
  const location = useLocation();
  const navigate = useNavigate();
  const { details, type } = location?.state || {};
  const [loading, setLoading] = useState(false);
  const [states, setStates] = useState([]);
  const [loadingLatLong, setLoadingLatLong] = useState(false);

  const [values, setValues] = useState({
    userId: details?.id || "",
    userType: details?.user_type
      ? { value: details?.user_type, label: details?.user_type }
      : { value: type, label: type },
    companyName: details?.company_name || "",
    userName: details?.userName || "",
    firstName: details?.first_name || "",
    lastName: details?.last_name || "",
    mobile: details?.mobile_number || "",
    email: details?.email || "",
    password: details?.password ? "" : "true",
    address: {
      streetName: details?.location?.streetName || "",
      city: details?.location?.city || "",
      state: details?.location?.state || "",
      zipCode: details?.location?.zipCode || "",
    },
    latitude: details?.latitude || "",
    longitude: details?.longitude || "",
  });

  useEffect(() => {
    const data = State.getStatesOfCountry("AU");
    const options = data.map((s) => ({
      value: s.name,
      label: s.name,
    }));
    setStates(options);
  }, []);

  const handleChangeValue = (e) => {
    const { name, value } = e.target;
    setValues({ ...values, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    toast.dismiss();
    if (validator.current.allValid()) {
      setLoading(true);
      values.userType = values?.userType?.label ? values?.userType?.label : "";
      values.status = '1';
      const res = await authCall("AddUsers", values);
      if (res?.type === "success") {
        toast.success(res.message);
        setLoading(false);
        navigate(AppConstants.routes.admin.users, {
          state: { type: details?.user_type },
        });
      } else {
        setLoading(false);
      }
    } else {
      validator.current.showMessages();
      forceUpdate(1);
    }
  };

  const handleChangeState = (name, selected) => {
    setValues({ ...values, [name]: selected });
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    if (name.includes(".")) {
      const [parent, child] = name.split(".");
      setValues((prevValues) => ({
        ...prevValues,
        [parent]: {
          ...prevValues[parent],
          [child]: value,
        },
      }));
    }
  };

  const handleChangePhone = (e) => {
    const inputValue = handleNumericInput(e.target.value);
    if (inputValue !== null && inputValue.length < 11) {
      setValues({
        ...values,
        mobile: inputValue,
      });
    }
  };

  const handleChangeZipcode = (e) => {
    const inputValue = handleNumericInput(e.target.value);
    if (inputValue !== null && inputValue.length < 8) {
      setValues((prevValues) => ({
        ...prevValues,
        address: {
          ...prevValues.address,
          zipCode: inputValue,
        },
      }));
    }
  };

  const handleChangeStateValue = (selected) => {
    setValues({ ...values, address: { ...values.address, state: selected } });
  };

  const handleUpdateLatLong = async () => {
    toast.dismiss();
    const address = {
      streetName: values?.address?.streetName,
      zipCode: values?.address?.zipCode,
      city: values?.address?.city,
      state: values?.address?.state?.label,
    };
    if (
      address?.streetName &&
      address?.zipCode &&
      address?.city &&
      address?.state
    ) {
      setLoadingLatLong(true);
      const resp = await authCall("getAddressLatLong", address);
      if (resp.type === "success") {
        setLoadingLatLong(false);
        setValues({
          ...values,
          latitude: resp?.payload?.Latitude,
          longitude: resp?.payload?.Longitude,
        });
      } else {
        setLoadingLatLong(false);
      }
    } else {
      toast.error("Invalid address");
    }
  };

  return (
    <>
      <div className="add-machine-page py-4">
        <div className="container custom-container">
          <div className="row">
            <div className="col-sm-4 col-lg-3 mb-4">
              <AdminSidebar />
            </div>
            <div className="col-sm-8 col-lg-9">
              <div className="common-info-card">
                <div className="heading-title">
                  {details?.id ? "Update" : "Add"} User
                </div>
                <div className="details-wrapper pb-lg-4">
                  <form
                    onSubmit={handleSubmit}
                    className="custom-field-wrapper"
                  >
                    <div className="info-cover mb-2">
                      <div className="main-heading2 mb-2">User Details:</div>
                      <div className="row mb-2">
                        <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6">
                          <label htmlFor="userType" className="fs-md-18">
                            User Type:
                          </label>
                          <Select
                            styles={customStyles}
                            className="text-body"
                            placeholder="Select User Type"
                            name="userType"
                            value={values.userType}
                            onChange={(e) => handleChangeState("userType", e)}
                            options={USERTYPE}
                          />
                          {validator.current.message(
                            "user type",
                            values.userType,
                            "required"
                          )}
                        </div>
                      </div>

                      <div className="row mb-2">
                        <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6">
                          <label htmlFor="companyName" className="fs-md-18">
                            Company Name:
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            id="companyName"
                            name="companyName"
                            autoComplete="off"
                            value={values.companyName}
                            onChange={(e) => handleChangeValue(e)}
                          />
                          {validator.current.message(
                            "company name",
                            values.companyName,
                            "required"
                          )}
                        </div>

                        <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6">
                          <label htmlFor="userName" className="fs-md-18">
                            User Name:
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            id="userName"
                            name="userName"
                            autoComplete="off"
                            value={values.userName}
                            onChange={(e) => handleChangeValue(e)}
                          />
                          {validator.current.message(
                            "user name",
                            values.userName,
                            "required"
                          )}
                        </div>
                      </div>

                      <div className="row mb-2">
                        <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6">
                          <label htmlFor="firstName" className="fs-md-18">
                            First Name:
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            id="firstName"
                            name="firstName"
                            autoComplete="off"
                            value={values.firstName}
                            onChange={(e) => handleChangeValue(e)}
                          />
                          {validator.current.message(
                            "first name",
                            values.firstName,
                            "required"
                          )}
                        </div>

                        <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6">
                          <label htmlFor="lastName" className="fs-md-18">
                            Last Name:
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            id="lastName"
                            name="lastName"
                            autoComplete="off"
                            value={values.lastName}
                            onChange={(e) => handleChangeValue(e)}
                          />
                          {validator.current.message(
                            "last name",
                            values.lastName,
                            "required"
                          )}
                        </div>
                      </div>

                      <div className="row mb-2">
                        <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6">
                          <label htmlFor="mobile" className="fs-md-18">
                            Phone Number:
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            id="mobile"
                            name="mobile"
                            autoComplete="off"
                            value={values.mobile}
                            onChange={handleChangePhone}
                          />
                          {validator.current.message(
                            "phone number",
                            values.mobile,
                            "required"
                          )}
                        </div>
                        <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6">
                          <label htmlFor="email" className="fs-md-18">
                            Email:
                          </label>
                          <input
                            type="email"
                            className="form-control"
                            id="email"
                            name="email"
                            autoComplete="off"
                            value={values.email}
                            onChange={(e) => handleChangeValue(e)}
                          />
                          {validator.current.message(
                            "email",
                            values.email,
                            "required"
                          )}
                        </div>
                      </div>
                    </div>

                    <hr className="divider my-4" />

                    <div className="info-cover mb-2">
                      <div className="main-heading2 mb-2">Address Details:</div>
                      <div className="row mb-2">
                        <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6">
                          <label htmlFor="streetAddress" className="fs-md-18">
                            Street Address::
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            id="streetAddress"
                            name="address.streetName"
                            autoComplete="off"
                            value={values.address.streetName}
                            onChange={handleChange}
                          />
                          {validator.current.message(
                            "street address",
                            values.address.streetName,
                            "required"
                          )}
                        </div>

                        <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6">
                          <label htmlFor="city" className="fs-md-18">
                            City:
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            id="city"
                            autoComplete="off"
                            name="address.city"
                            value={values.address.city}
                            onChange={handleChange}
                          />
                          {validator.current.message(
                            "city",
                            values.address.city,
                            "required"
                          )}
                        </div>
                      </div>

                      <div className="row mb-2">
                        <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6">
                          <label htmlFor="state" className="fs-md-18">
                            State:
                          </label>
                          <Select
                            styles={customStyles}
                            className="text-body"
                            placeholder="Select State"
                            value={values.address.state}
                            name="address.state"
                            onChange={handleChangeStateValue}
                            options={states}
                          />
                          {validator.current.message(
                            "state",
                            values.address.state,
                            "required"
                          )}
                        </div>

                        <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6">
                          <label htmlFor="eventCategory" className="fs-md-18">
                            Post Code:
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            id="postCode"
                            autoComplete="off"
                            name="address.zipCode"
                            value={values.address.zipCode}
                            onChange={handleChangeZipcode}
                          />
                          {validator.current.message(
                            "post code",
                            values.address.zipCode,
                            "required"
                          )}
                        </div>
                      </div>

                      <div className="row mb-2">
                        <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6">
                          <label htmlFor="state" className="fs-md-18">
                            Latitude:
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            autoComplete="off"
                            name="latitude"
                            value={values.latitude}
                            onChange={(e) => handleChangeValue(e)}
                          />
                          {validator.current.message(
                            "latitude",
                            values.latitude,
                            "required"
                          )}
                        </div>

                        <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6">
                          <label htmlFor="eventCategory" className="fs-md-18">
                            Longitude:
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            autoComplete="off"
                            name="longitude"
                            value={values.longitude}
                            onChange={(e) => handleChangeValue(e)}
                          />
                          {validator.current.message(
                            "longitude",
                            values.longitude,
                            "required"
                          )}
                          <Button
                            className="btn btn-custom btn-custom-sm fs-16 mt-3 f-right"
                            style={{ float: "right" }}
                            onClick={handleUpdateLatLong}
                            disabled={loadingLatLong || false}
                          >
                            {loadingLatLong
                              ? "Loading..."
                              : "Update Lat & Long"}
                          </Button>
                        </div>
                      </div>
                    </div>

                    <hr className="divider my-5" />

                    <div className="btn-wrapper text-center mt-4 mb-2 pt-2">
                      <button
                        type="submit"
                        className="btn btn-custom"
                        disabled={loading || false}
                      >
                        {loading
                          ? "Loading..."
                          : details?.id
                          ? "Update"
                          : "Submit"}
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default UserAdd;
