import React, { useState } from "react";
import { Link } from "react-router-dom";
import moment from "moment";
import Modal from "react-bootstrap/Modal";

function BookingTextMessagePreviewPopUp({ show, handleClose, details }) {
  const [date] = useState(moment().format("dddd [the] Do MMMM"));
  const [time] = useState(moment().format("hh:mm A"));

  return (
    <>
      <Modal
        show={show}
        centered
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        dialogClassName="custom-modal"
      >
        <Modal.Header closeButton>
          <Modal.Title className="fw-bold fs-20">
            Booking Confirm Text Message Preview
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="py-4">
          <div className="qr-cover">
            Congratulations your booking has been confirmed. Your reference
            number is: #ORD9898
          </div>

          <div className="qr-cover">
            ACTIVITY:{" "}
            {details?.activity_name ? details?.activity_name : "[Actvity Name]"}
          </div>

          <div className="qr-cover">DATE: {date}</div>

          <div className="qr-cover">TIME: {time}</div>

          <div className="qr-cover mt-2 mb-3">{details?.what_to_bring}</div>
          <div className="qr-cover mb-3">
            {details?.locationDescription
              ? details?.locationDescription
              : "[Location Description]"}
          </div>

          {details?.isBookingCancel ? (
            <>
              <div className="qr-cover">
                CANCELATIONS: <br />
                Congratulations your booking is covered by Touch-iT’s 24-hour
                booking guarantee. Please visit{" "}
                <Link to="https://www.touch-it.com.au/cancel">
                  https://www.touch-it.com.au/cancel
                </Link>{" "}
                up to 24 hours before your activity to handle any cancelation or
                reschedule requests.
              </div>
            </>
          ) : (
            <div className="qr-cover">
              CANCELATIONS: <br />
              Your booking is covered by{" "}
              {details?.company_name
                ? details?.company_name
                : "[company name]"}{" "}
              cancelation policy:{" "}
              <Link to="https://www.touch-it.com.au/cancel">
                https://www.touch-it.com.au/cancel
              </Link>{" "}
              <br />
              Please call{" "}
              {details?.company_phone ? details?.company_phone : "[Phone]"} to
              handle any cancelation or reschedule requests.
            </div>
          )}
        </Modal.Body>
      </Modal>
    </>
  );
}

export default BookingTextMessagePreviewPopUp;
