import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { setUser } from "../../../redux/actions/user.actions";
import { useNavigate } from "react-router-dom";
import AppConstants from "../../../appConstants";
import { authCall } from "../../../services";

const OnboardComplete = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { current } = useSelector((state) => state.user);

  useEffect(() => {
    const checkOnboardingStatus = async () => {
      const response = await authCall("checkOnboardingStatus", {
        userId: current?.id,
      });
      if (response?.payload?.payouts_enabled) {
        dispatch(setUser({...current, stripe_account_id: response?.payload?.id}));
        navigate(AppConstants.routes.admin.dashboard);
      } else {
        navigate(AppConstants.routes.tour.createCompany);
      }
    };

    checkOnboardingStatus();
  }, [navigate]);

  return <div className="loader">Checking onboarding status...</div>;
};

export default OnboardComplete;
