import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import TestimonialsComponent from "./TestimonialsComponent";
import { authCall } from "../services";
import { generateSku } from "./util/utils";
import BannerComponent from "./BannerComponent";
import BannerSubComponent from "./BannerSubComponent";
import AppConstants from "../appConstants";
import StarRating from "./common/StarRating";

function CompanyActivityListComponent() {
  const location = useLocation();
  const navigate = useNavigate();
  const { companyName } = location.state || {};
  const { pathname } = location;
  const [details, setDetails] = useState([]);
  const [banners, setBanners] = useState([]);
  const [exclSkelArray] = useState(new Array(4).fill());
  const [loading, setLoading] = useState(false);
  const [searchType, setSearchType] = useState("");

  useEffect(() => {
    async function getData() {
      setLoading(true);
      let type = "";
      if (pathname === AppConstants.routes.bookingSearch) {
        type = "booking";
        setSearchType("booking");
      } else if (pathname === AppConstants.routes.dinningSearch) {
        type = "dinning";
        setSearchType("dinning");
      }
      if (pathname === AppConstants.routes.nightlifelanding) {
        type = "nightlife";
        setSearchType("nightlife");
      }

      const resp = await authCall("getCompanyActivities", { companyName });
      if (resp?.type === "success") {
        setDetails(resp?.payload?.details || []);
        setBanners(resp?.payload?.banners || []);
        setLoading(false);
      } else {
        setLoading(false);
      }
    }
    getData();
  }, []);

  const handleClickBooking = (item) => {
    const name = generateSku(item.name);
    navigate(`/booking/${name}`, { state: { activityId: item.id } });
  };

  return (
    <>
      {/* BANNER */}
      <BannerComponent
        banner={banners}
        title={companyName}
        subTitle="Experience it all"
        // button={{ title: "Search", link: AppConstants.routes.bookingSearch }}
        button={{ title: "Back" }}
      />

      {/* BEST PRICE GUARANTEED */}
      <BannerSubComponent />

      <section className="top-experiences result-listing py-3">
        <div className="container">
          <div className="row util-carousell">
            {loading &&
              exclSkelArray.map(() => (
                <div className="col-xs-6 col-md-4 col-lg-3 mb-4 item">
                  <div className="join-blocks">
                    <Skeleton
                      className="join-blocks-img"
                      style={{ borderRadius: 13 }}
                    />
                    <Skeleton count={1} width="80%" />
                    <Skeleton count={1} className="mt-3" width="60%" />
                    <Skeleton count={1} className="mt-3" width="30%" />
                  </div>
                </div>
              ))}

            {!loading &&
              details.map((el, index) => {
                return (
                  <div
                    key={index}
                    className="col-xs-6 col-md-4 col-lg-3 mb-4 item"
                  >
                    <div className="join-blocks join-blocks-new">
                      <div className="join-blocks-img">
                        <img
                          src={el.profile_photo}
                          className="img-fluid cursor-pointer"
                          alt={el.name}
                          onClick={() => handleClickBooking(el)}
                        />
                      </div>

                      <h4
                        className="cursor-pointer"
                        onClick={() => handleClickBooking(el)}
                      >
                        {el.name}
                      </h4>
                      <StarRating rating={el?.total_ratings} />
                      {el?.reviews && (
                        <span className="price">
                          <ins>Based on {el?.reviews} Reviews</ins>
                        </span>
                      )}
                      {el?.display_price?.price && (
                        <span className="price">
                          {el?.display_price?.advertisePrice && (
                            <>
                              from{" "}
                              <del>${el?.display_price?.advertisePrice}</del>
                            </>
                          )}
                          <ins>AUD ${el?.display_price?.total_including_tax ? el?.display_price?.total_including_tax/100 : el?.display_price?.price}</ins>
                        </span>
                      )}
                    </div>
                  </div>
                );
              })}
          </div>
        </div>
      </section>

      <section className="top-experiences testimonials mt-5 py-5">
        <TestimonialsComponent />
      </section>
    </>
  );
}

export default CompanyActivityListComponent;
