import React, { useEffect, useState } from "react";
import AdminSidebar from "../../layouts/AdminSidebar";
import { toast } from "react-toastify";
import { useNavigate, useLocation } from "react-router-dom";
import moment from "moment";
import { useSelector } from "react-redux";
import webServices from "../../../services/webServices";
import AppConstants from "../../../appConstants";
import checkIcon from "./../../../assets/images/admin/check-icon.png";
import ImageUploads from "../../util/ImageUploads";
import BannerImagesUploads from "../../util/BannerImagesUploads";

function UpdateEventDetails() {
  const location = useLocation();
  const navigate = useNavigate();
  const { current } = useSelector((state) => state.user);
  const { item } = location.state || {};
  const [profileImage, setProfileImage] = useState("");
  const [loading, setLoading] = useState(false);
  const [background, setBackground] = useState([]);
  const [backgroundImgPreview, setBackgroundImgPreview] = useState("");
  const [bannerImages, setBannerImages] = useState([]);
  const [isProfileImageChange, setIsProfileImageChange] = useState(false);
  const [isBannerImageChange, setIsBannerImageChange] = useState(false);
  const [isBackgroundImageUpload, setIsBackgroundImageUpload] = useState(false);

  const [highlights, setHighlights] = useState([
    { id: 1, value: "" },
    { id: 2, value: "" },
    { id: 3, value: "" },
    { id: 4, value: "" },
    { id: 5, value: "" },
    { id: 6, value: "" },
  ]);
  const [exclusions, setExclusions] = useState([
    { id: 1, value: "" },
    { id: 2, value: "" },
    { id: 3, value: "" },
  ]);

  const [values, setValues] = useState({
    name: "",
    coast: "",
    startDate: moment().format("Y-MM-DD"),
    recurring: "",
    startTime: "12:00",
    endTime: "23:45",
    highlights: [],
    exclusions: [],
    overview: "",
    profileImage: "",
    bannerImage: "",
    offerText: "",
    offerText2: "",
    backgroundImage: "",
    backgroundImageOther: "",
  });

  useEffect(() => {
    async function fetchData() {
      const resp = await webServices.getNightLifeBackground();
      if (resp?.type === "success") {
        setBackground(resp?.payload);
      }
    }
    fetchData();
    setValues({
      ...values,
      eventId: item?.id,
      name: item?.event_name || "",
      coast: item?.event_cost || "",
      startDate: item?.event_start_date ? moment(item?.event_start_date).format("Y-MM-DD") : moment().format("Y-MM-DD"),
      recurring: item?.event_recurring || "",
      startTime: item?.event_start_time || "12:00",
      endTime: item?.event_end_time || "23:45",
      overview: item?.event_overview || "",
      profileImage: item?.profile_image_path || "",
      bannerImage: "",
      offerText: item?.offer_text || "",
      offerText2: item?.offer_text2 || "",
      backgroundImage: item?.background_image || "",
      backgroundImageOther: "",
      isBackgroundImageUpload: false,
    });
    setHighlights(item?.highlights || highlights);
    setExclusions(item?.exclusions || exclusions);
    setProfileImage(item?.profile_image_path || "");
    setBannerImages(item?.event_banner_photo || bannerImages);
    setBackgroundImgPreview(item?.bg_image_path || "");
  }, []);

  const handleChange = (event) => {
    const { name, value } = event.target;
    if (name.includes(".")) {
      const [parent, child] = name.split(".");
      setValues((prevValues) => ({
        ...prevValues,
        [parent]: {
          ...prevValues[parent],
          [child]: value,
        },
      }));
    } else if (name === "startDate") {
      setValues({ ...values, [name]: value, recurring: "" });
    } else {
      setValues((prevValues) => ({
        ...prevValues,
        [name]: value,
      }));
    }
  };

  const handleChangeHighlight = (id, value) => {
    setHighlights(
      highlights.map((field) =>
        field.id === id ? { ...field, value: value } : field
      )
    );
  };

  const handleChangeExclusion = (id, value) => {
    setExclusions(
      exclusions.map((field) =>
        field.id === id ? { ...field, value: value } : field
      )
    );
  };

  const handleSubmit = async (val) => {
    values.nightLifeId = item?.nightLifeId;
    values.highlights = highlights;
    values.exclusions = exclusions;
    values.profileImage = isProfileImageChange ? profileImage : "";
    values.bannerImage = isBannerImageChange ? bannerImages : "";
    values.backgroundImageOther = isBackgroundImageUpload
      ? backgroundImgPreview
      : "";
    values.isTranslate = true;

    setLoading(true);
    const resp = await webServices.createEvents(values);
    if (resp.type === "success") {
      setLoading(false);

      if (current?.user_type === "Admin") {
        navigate(AppConstants.routes.admin.nightLifeList);
      } else {
        navigate(AppConstants.routes.admin.nightlifeEditListing);
      }
      toast.success(`${item ? "Updated" : "Added"} successfully`);
      setIsProfileImageChange(false);
      setIsBannerImageChange(false);
      setIsBackgroundImageUpload(false);
      window.scrollTo(0, 0);
    }
    setLoading(false);
  };

  const handleChangeRecurring = (event) => {
    const { checked } = event.target;
    if (checked && values.startDate !== "") {
      const day = moment(values.startDate).format("dddd");
      setValues({ ...values, recurring: day });
    } else {
      setValues({ ...values, recurring: "" });
    }
  };

  const handleChangeBackground = (event) => {
    const selectedValue = event.target.value;
    const selectedObject = background.find(
      (option) => option.image === selectedValue
    );

    setBackgroundImgPreview(selectedObject.image_path);
    setValues({ ...values, backgroundImage: selectedObject.image });
  };

  const getBannerImages = (banners) => {
    setBannerImages(banners);
    setIsBannerImageChange(true);
  };

  const getProfileImage = (img) => {
    setProfileImage(img);
    setIsProfileImageChange(true);
  };

  const getBackgroundImage = (img) => {
    setBackgroundImgPreview(img);
    setIsBackgroundImageUpload(true);
  };

  return (
    <>
      <div className="restaurant-details-page venue-details-page py-4">
        <div className="container custom-container">
          <div className="row">
            <div className="col-md-4 col-lg-3 mb-4">
              <AdminSidebar />
            </div>
            <div className="col-md-8 col-lg-9">
              <div className="common-info-card">
                <div className="heading-title">
                  Please {item?.id ? "Update" : "Enter"} Your Event Details:
                </div>
                <div className="details-wrapper">
                  <div className="sub-title mb-3">
                    These details will appear on your activity listing
                  </div>
                  <div action="" className="custom-field-wrapperr">
                    <div className="info-cover mb-4">
                      <div className="main-heading2 mb-2">Event Details:</div>
                      <div className="row event-block">
                        <div className="col-6 col-lg-4">
                          <div className="form-group">
                            <label htmlFor="eventName" className="mb-1">
                              Event Name :
                            </label>
                            <input
                              type="text"
                              className="form-control rounded-pill"
                              id="eventName"
                              placeholder=""
                              autoComplete="off"
                              name="name"
                              value={values.name}
                              onChange={handleChange}
                            />
                          </div>
                        </div>
                        <div className="col-6 col-lg-4">
                          <div className="form-group">
                            <label htmlFor="eventCost" className="mb-1">
                              Event Cost :
                            </label>
                            <input
                              type="number"
                              className="form-control rounded-pill"
                              id="eventCost"
                              placeholder=""
                              autoComplete="off"
                              name="coast"
                              value={values.coast}
                              onChange={handleChange}
                            />
                          </div>
                        </div>
                      </div>

                      <div className="row event-block">
                        <div className="col-lg-4">
                          <div className="form-group">
                            <label htmlFor="eventStartDate" className="mb-1">
                              Event Date :
                            </label>
                            <input
                              type="date"
                              className="form-control rounded-pill"
                              autoComplete="off"
                              name="startDate"
                              min={moment().format("Y-MM-DD")}
                              onChange={handleChange}
                              value={values.startDate}
                            />
                          </div>
                          <div className="form-group">
                            <div className="d-flex align-items-center gap-2">
                              <input
                                type="checkbox"
                                className="custom-checkbox m-0"
                                name="recurring"
                                id="Recurring"
                                checked={values?.recurring || false}
                                onChange={handleChangeRecurring}
                              />
                              <label className="m-0" htmlFor="Recurring">
                                Recurring Weekly
                              </label>
                            </div>
                          </div>
                        </div>
                        <div className="col-6 col-lg-4">
                          <div className="form-group">
                            <label htmlFor="eventStartTime" className="mb-1">
                              Event Start Time :
                            </label>

                            <input
                              type="time"
                              className="form-control rounded-pill"
                              autoComplete="off"
                              name="startTime"
                              onChange={handleChange}
                              value={values.startTime}
                            />
                          </div>
                        </div>
                        <div className="col-6 col-lg-4">
                          <div className="form-group">
                            <label htmlFor="eventStartTime" className="mb-1">
                              Event End Time :
                            </label>
                            <input
                              type="time"
                              className="form-control rounded-pill"
                              autoComplete="off"
                              name="endTime"
                              onChange={handleChange}
                              value={values.endTime}
                            />
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="info-cover mb-1 mb-lg-4 pb-2">
                      <div className="main-heading2 text-white mb-2 pb-1">
                        Highlight:
                      </div>
                      <div className="row">
                        {highlights.map((field, index) => (
                          <div key={index} className="col-sm-6 col-lg-4">
                            <div className="form-group d-flex align-items-center gap-2">
                              <div className="icon">
                                <img
                                  src={checkIcon}
                                  className="img-responsive"
                                  alt="Check"
                                />
                              </div>
                              <input
                                type="text"
                                className="form-control rounded-pill"
                                placeholder="Hightlight"
                                autoComplete="off"
                                value={field.value}
                                onChange={(e) =>
                                  handleChangeHighlight(
                                    field.id,
                                    e.target.value
                                  )
                                }
                              />
                            </div>
                          </div>
                        ))}
                      </div>
                    </div>

                    <div className="info-cover mb-3 mb-lg-4">
                      <div className="main-heading2 text-white mb-2 pb-1">
                        Exclusion:
                      </div>
                      <div className="row">
                        {exclusions.map((field, index) => (
                          <div key={index} className="col-sm-6 col-lg-4">
                            <div className="form-group d-flex align-items-center gap-2">
                              <div className="icon">
                                <img
                                  src={checkIcon}
                                  className="img-responsive"
                                  alt="Check"
                                />
                              </div>
                              <input
                                type="text"
                                className="form-control rounded-pill"
                                id=""
                                placeholder="Exclusion"
                                autoComplete="off"
                                value={field.value}
                                onChange={(e) =>
                                  handleChangeExclusion(
                                    field.id,
                                    e.target.value
                                  )
                                }
                              />
                            </div>
                          </div>
                        ))}
                      </div>
                    </div>

                    <div className="info-cover mb-4 pb-2">
                      <div className="main-heading2 text-white mb-2 pb-1">
                        Overview:
                      </div>
                      <textarea
                        id="overview"
                        rows="5"
                        className="form-control"
                        placeholder="Please provide a 75-to-150-word descripition of activity."
                        autoComplete="off"
                        name="overview"
                        value={values.overview}
                        onChange={handleChange}
                      ></textarea>
                    </div>

                    <div className="event-details-page info-cover mb-4">
                      <div className="row">
                        <div className="col-lg-5 mb-4 mb-lg-0">
                          <div className="info-cover">
                            <div className="main-heading2 mb-1">Images:</div>
                            <div className="profile-cover mb-3">
                              <div className="main-heading2 text-white mb-1">
                                Profile Images:
                              </div>
                              <p className="fs-12">
                                <i>
                                  (This is the image shown on the search results
                                  page)
                                </i>
                              </p>
                              <ImageUploads
                                getImage={getProfileImage}
                                uploadedImage={profileImage}
                                aspectRation={1}
                                croppedSize={{
                                  width: 870,
                                  height: 580,
                                  imageSmoothingQuality: "high",
                                }}
                              />
                            </div>
                            <BannerImagesUploads
                              bannerImages={getBannerImages}
                              images={bannerImages}
                            />
                          </div>
                        </div>
                        <div className="col-lg-7">
                          <div className="info-cover">
                            <div className="main-heading2 mb-1">
                              Drink Offer:
                            </div>
                            <div className="offer-cover">
                              <div className="main-heading2 text-white mb-1">
                                Offer Text:
                              </div>
                              <p className="fs-12">
                                <i>
                                  (Describe your offer below in 5 words or less.
                                  For example Three Free Cocktails)
                                </i>
                              </p>
                              <div className="row custom-row">
                                <div className="col-sm-6">
                                  <div className="form-group">
                                    <input
                                      type="text"
                                      id=""
                                      className="form-control rounded-pill"
                                      placeholder=""
                                      autoComplete="off"
                                      name="offerText"
                                      maxLength={10}
                                      value={values.offerText}
                                      onChange={handleChange}
                                    />
                                  </div>
                                </div>
                                <div className="col-sm-6">
                                  <div className="form-group">
                                    <input
                                      type="text"
                                      id=""
                                      className="form-control rounded-pill"
                                      placeholder=""
                                      autoComplete="off"
                                      name="offerText2"
                                      maxLength={10}
                                      value={values.offerText2}
                                      onChange={handleChange}
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="banner-cover mb-2">
                              <div className="main-heading2 text-white mb-1">
                                Select Background Images:
                              </div>
                              <p className="fs-12">
                                <i>
                                  (Select the background image from the list
                                  below)
                                </i>
                              </p>
                              <div className="row">
                                <div className="col-sm-6">
                                  <div className="form-group">
                                    <select
                                      className="form-select rounded-pill"
                                      name="backgroundImage"
                                      onChange={handleChangeBackground}
                                    >
                                      <option value="" hidden="">
                                        Select
                                      </option>
                                      {background.map((el, index) => (
                                        <option
                                          key={index}
                                          value={el.image}
                                          selected={
                                            el.image ===
                                              values.backgroundImage || false
                                          }
                                        >
                                          {el.name}
                                        </option>
                                      ))}
                                    </select>
                                  </div>
                                  <div className="form-group">
                                    <div className="custom-input-file mb-2">
                                      <ImageUploads
                                        getImage={getBackgroundImage}
                                        uploadedImage=""
                                        isBackground={true}
                                      />
                                    </div>
                                  </div>
                                </div>
                                {backgroundImgPreview && (
                                  <div className="col-sm-6">
                                    <div
                                      className="wrapper"
                                      style={{ maxWidth: "200px" }}
                                    >
                                      <div className="preview-box rounded-3">
                                        <img
                                          src={backgroundImgPreview}
                                          className="img-fluid border-0 rounded-3"
                                          alt={backgroundImgPreview}
                                        />
                                        <div
                                          className="moon-wrapper position-absolute start-0 top-0 h-100"
                                          style={{
                                            backgroundImage: `url(${require("../../../assets/images/gallery/half-moon.png")})`,
                                          }}
                                        >
                                          <div className="rel-text">
                                            <div className="text-truncate">
                                              {values.offerText}
                                            </div>
                                            <div className="text-truncate mb-1">
                                              {values.offerText2}
                                            </div>
                                            <button
                                              type="button"
                                              className="btn btn-custom2 rounded-0"
                                              style={{ marginLeft: "-5px" }}
                                            >
                                              Click to Reveal
                                            </button>
                                          </div>
                                        </div>
                                      </div>
                                      <div className="text-center fw-medium mt-2">
                                        <i>Preview</i>
                                      </div>
                                    </div>
                                  </div>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="btn-wrapper d-flex flex-column flex-md-row flex-md-wrap justify-content-center gap-3">
                      <button
                        type="submit"
                        className="btn btn-custom"
                        onClick={() => handleSubmit("submit")}
                        disabled={loading || false}
                      >
                        {loading
                          ? item
                            ? "Updating..."
                            : "Submitting..."
                          : item
                          ? "Update"
                          : "Submit"}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default UpdateEventDetails;
