import React, { useEffect, useState, useRef } from "react";
import { toast } from "react-toastify";
import moment from "moment";
import SimpleReactValidator from "simple-react-validator";
import Select from "react-select";
import { State } from "country-state-city";
import { useNavigate, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import AdminSidebar from "../../layouts/AdminSidebar";
import { handleNumericInput } from "../../util/utils";
import { authCall } from "../../../services";
import AppConstants from "../../../appConstants";
import ImageUploads from "../../util/ImageUploads";

const currentDateTime = moment().format("YYYY-MM-DD HH:mm");

const CATEGORY = [
  { value: "Business Event", label: "Business Event" },
  { value: "Classes, Lessons", label: "Classes and Lessons" },
  { value: "Community Event", label: "Community Event" },
  { value: "Concert, Performance", label: "Concert or Performance" },
  { value: "Exhibition", label: "Exhibition and Shows" },
  { value: "Festivals, Celebrations", label: "Festivals and Celebrations" },
  { value: "Food, Wine", label: "Food and Wine" },
  { value: "Markets", label: "Markets" },
  { value: "Sporting Events", label: "Sporting Events" },
];

// const CATEGORY = [
//   { value: "Air Show", label: "Air Show" },
//   { value: "Arts & Culture", label: "Arts & Culture" },
//   { value: "Circus", label: "Circus" },
//   { value: "Community", label: "Community" },
//   { value: "Exhibitions & Festivals", label: "Exhibitions & Festivals" },
//   { value: "Family Friendly", label: "Family Friendly" },
//   { value: "Firework", label: "Firework" },
//   { value: "Food & Drink", label: "Food & Drink" },
//   { value: "Major Events", label: "Major Events" },
//   { value: "Markets", label: "Markets" },
//   { value: "Music", label: "Music" },
//   { value: "Nature", label: "Nature" },
//   { value: "Races", label: "Races" },
//   { value: "Senior", label: "Senior" },
//   { value: "Sports", label: "Sports" },
// ];

const customStyles = {
  control: (provided) => ({
    ...provided,
    backgroundColor: "#bababa",
    border: "2px solid #fff",
  }),
  option: (provided, state) => ({
    ...provided,
    backgroundColor: state.isSelected
      ? "#007BFF"
      : state.isFocused
      ? "#e0e0e0"
      : "#fff",
    color: state.isSelected ? "#fff" : "#000",
    "&:hover": {
      backgroundColor: "#e0e0e0",
    },
  }),
  singleValue: (provided, state) => ({
    ...provided,
    color: "#000",
  }),
  menu: (provided, state) => ({
    ...provided,
    backgroundColor: "#fff",
    zIndex: 9999,
  }),
  menuList: (provided, state) => ({
    ...provided,
    backgroundColor: "#fff",
  }),
  placeholder: (provided) => ({
    ...provided,
    color: "#383636",
    // fontWeight: 600,
  }),
  dropdownIndicator: (provided) => ({
    ...provided,
    color: "#383636",
  }),
};

let nextId = 2031;

function EventsAdd() {
  const validator = useRef(new SimpleReactValidator());
  const [, forceUpdate] = useState();
  const location = useLocation();
  const navigate = useNavigate();
  const { current } = useSelector((state) => state.user);
  const { details, actionType } = location.state || {};
  const [loading, setLoading] = useState(false);
  const [states, setStates] = useState([]);
  const [profileImage, setProfileImage] = useState(details?.event_photo || "");
  const [isEventPhotoChange, setIsEventPhotoChange] = useState(false);
  const [dateRanges, setDateRanges] = useState([
    {
      id: nextId++,
      eventStartDateTime: details?.event_start_time || "",
      eventEndDateTime: details?.event_end_time || "",
    },
  ]);

  const [values, setValues] = useState({
    eventId: details?.id || "",
    userId: current?.id,
    eventTitle: details?.title || "",
    eventCategory: details?.category
      ? { label: details?.category, value: details?.category }
      : "",
    eventPrice: details?.event_price || "",
    mainEvent: details?.is_main_event === "1" ? 1 : 0,
    streetAddress: details?.street_address || "",
    city: details?.city || "",
    state: details?.state
      ? { label: details?.state, value: details?.state }
      : "",
    postCode: details?.post_code || "",
    phoneNumber: details?.phone_number || "",
  });

  useEffect(() => {
    const data = State.getStatesOfCountry("AU");
    const options = data.map((s) => ({
      value: s.name,
      label: s.name,
    }));
    setStates(options);
  }, []);

  const handleChangeValue = (e) => {
    const { name, value } = e.target;
    setValues({ ...values, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    toast.dismiss();
    if (validator.current.allValid()) {
      setLoading(true);

      values.eventPhoto = isEventPhotoChange ? profileImage : "";
      values.eventCategory = values?.eventCategory?.label
        ? values?.eventCategory?.label
        : "";
      values.state = values?.state?.label ? values?.state?.label : "";
      values.dateRanges = dateRanges;

      const res = await authCall("createEvents", values);
      if (res?.type === "success") {
        toast.success(res.message);
        setLoading(false);
        const navi =
          actionType === "live"
            ? AppConstants.routes.admin.liveEvents
            : AppConstants.routes.admin.archivedEvents;
        navigate(navi);
      } else {
        setLoading(false);
      }
    } else {
      validator.current.showMessages();
      forceUpdate(1);
      window.scrollTo(0, 0);
    }
  };

  const handleChangeState = (name, selected) => {
    setValues({ ...values, [name]: selected });
  };

  const getProfileImage = (img) => {
    setProfileImage(img);
    setIsEventPhotoChange(true);
  };

  const handleInputChange = (id, event) => {
    const newDateRanges = dateRanges.map((dateRange) => {
      if (dateRange.id === id) {
        return { ...dateRange, [event.target.name]: event.target.value };
      }
      return dateRange;
    });
    setDateRanges(newDateRanges);
  };

  const addDateRange = () => {
    setDateRanges([
      ...dateRanges,
      { id: nextId++, eventStartDateTime: "", eventEndDateTime: "" },
    ]);
  };

  const removeDateRange = (id) => {
    const newDateRanges = dateRanges.filter((dateRange) => dateRange.id !== id);
    setDateRanges(newDateRanges);
  };

  return (
    <>
      <div className="add-machine-page py-4">
        <div className="container custom-container">
          <div className="row">
            <div className="col-sm-4 col-lg-3 mb-4">
              <AdminSidebar />
            </div>
            <div className="col-sm-8 col-lg-9">
              <div className="common-info-card">
                <div className="heading-title">
                  {details?.id ? "Update" : "Add"} Events
                </div>
                <div className="details-wrapper pb-lg-4">
                  <form
                    onSubmit={handleSubmit}
                    className="custom-field-wrapper"
                  >
                    <div className="info-cover mb-2">
                      <div className="main-heading2 mb-2">Event Details:</div>
                      <div className="row mb-2">
                        <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6">
                          <label htmlFor="eventTitle" className="fs-md-18">
                            Event Title:
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            id="eventTitle"
                            name="eventTitle"
                            autoComplete="off"
                            value={values.eventTitle}
                            onChange={(e) => handleChangeValue(e)}
                          />
                          {validator.current.message(
                            "event title",
                            values.eventTitle,
                            "required"
                          )}
                        </div>

                        <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6">
                          <label htmlFor="eventCategory" className="fs-md-18">
                            Event Category:
                          </label>
                          <Select
                            styles={customStyles}
                            className="text-body"
                            placeholder="Select Category"
                            name="eventCategory"
                            value={values.eventCategory}
                            onChange={(e) =>
                              handleChangeState("eventCategory", e)
                            }
                            options={CATEGORY}
                          />
                          {validator.current.message(
                            "event category",
                            values.eventCategory,
                            "required"
                          )}
                        </div>
                      </div>

                      {dateRanges.map((el, index) => (
                        <div key={index} className="row mb-0">
                          <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6 mb-1">
                            <label
                              htmlFor={`{eventStartDateTime${index}`}
                              className="fs-md-18 mt-1"
                            >
                              Event Start Date & time:
                            </label>
                            <input
                              type="datetime-local"
                              className="form-control"
                              id={`{eventStartDateTime${index}`}
                              name="eventStartDateTime"
                              min={currentDateTime}
                              autoComplete="off"
                              value={el.eventStartDateTime}
                              onChange={(event) =>
                                handleInputChange(el.id, event)
                              }
                            />
                            {validator.current.message(
                              "event start date & time",
                              dateRanges[index].eventStartDateTime,
                              "required"
                            )}
                          </div>

                          <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6 mb-1">
                            <label
                              htmlFor={`{eventEndDateTime${index}`}
                              className="fs-md-18 mt-1"
                            >
                              Event End Date & Time:
                            </label>
                            <input
                              type="datetime-local"
                              className="form-control"
                              id={`{eventEndDateTime${index}`}
                              name="eventEndDateTime"
                              min={currentDateTime}
                              autoComplete="off"
                              value={el.eventEndDateTime}
                              onChange={(event) =>
                                handleInputChange(el.id, event)
                              }
                            />
                            {validator.current.message(
                              "event end date & time",
                              dateRanges[index].eventEndDateTime,
                              "required"
                            )}
                          </div>
                          {dateRanges.length > 1 && (
                            <div className="text-red text-end">
                              <span
                                className="text-red cursor-pointer"
                                aria-hidden="true"
                                onClick={() => removeDateRange(el.id)}
                              >
                                Remove
                              </span>
                            </div>
                          )}
                        </div>
                      ))}
                      {!details?.id && (
                        <div className="fw-medium text-end">
                          <span
                            className="add-another glyphicon glyphicon-plus"
                            aria-hidden="true"
                            onClick={addDateRange}
                          >
                            Add Another Date
                          </span>
                        </div>
                      )}

                      <div className="row mb-2">
                        <div className="col-xs-12 col-sm-12 col-md-3 col-lg-3 mb-1">
                          <label htmlFor="eventPrice" className="fs-md-18 mt-1">
                            Event Price:
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            id="eventPrice"
                            name="eventPrice"
                            autoComplete="off"
                            value={values.eventPrice}
                            onChange={(e) => {
                              const inputValue = handleNumericInput(
                                e.target.value
                              );
                              if (
                                inputValue !== null &&
                                inputValue.length < 8
                              ) {
                                setValues({
                                  ...values,
                                  eventPrice: e.target.value,
                                });
                              }
                            }}
                          />
                        </div>
                        <div className="col-xs-12 col-sm-12 col-md-3 col-lg-3 mb-1">
                          <div
                            className="form-group d-flex align-items-center gap-3"
                            style={{ marginTop: 36 }}
                          >
                            <input
                              type="checkbox"
                              name="mainEvent"
                              id="mainEvent"
                              checked={values.mainEvent}
                              onChange={(e) =>
                                setValues({
                                  ...values,
                                  mainEvent: e.target.checked ? 1 : 0,
                                })
                              }
                            />
                            <label className="m-0" htmlFor="mainEvent">
                              Main Event
                            </label>
                          </div>
                        </div>
                        <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6 mb-1">
                          <label className="fs-md-18 mt-1">Event Photo:</label>
                          <ImageUploads
                            getImage={getProfileImage}
                            uploadedImage={details?.event_photo || profileImage}
                            aspectRation={1.45 / 1}
                            croppedSize={{
                              width: 323,
                              height: 223,
                              imageSmoothingQuality: "high",
                            }}
                          />
                          {validator.current.message(
                            "event photo",
                            profileImage,
                            "required"
                          )}
                        </div>
                      </div>
                    </div>

                    <hr className="divider my-4" />

                    <div className="info-cover mb-2">
                      <div className="main-heading2 mb-2">Venue Details:</div>
                      <div className="row mb-2">
                        <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6">
                          <label htmlFor="streetAddress" className="fs-md-18">
                            Street Address::
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            id="streetAddress"
                            name="streetAddress"
                            autoComplete="off"
                            value={values.streetAddress}
                            onChange={(e) => handleChangeValue(e)}
                          />
                          {validator.current.message(
                            "street address",
                            values.streetAddress,
                            "required"
                          )}
                        </div>

                        <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6">
                          <label htmlFor="city" className="fs-md-18">
                            City:
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            id="city"
                            name="city"
                            autoComplete="off"
                            value={values.city}
                            onChange={(e) => handleChangeValue(e)}
                          />
                          {validator.current.message(
                            "city",
                            values.city,
                            "required"
                          )}
                        </div>
                      </div>

                      <div className="row mb-2">
                        <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6">
                          <label htmlFor="state" className="fs-md-18">
                            State:
                          </label>
                          <Select
                            styles={customStyles}
                            className="text-body"
                            placeholder="Select State"
                            value={values.state}
                            name="state"
                            onChange={(e) => handleChangeState("state", e)}
                            options={states}
                          />
                          {validator.current.message(
                            "state",
                            values.state,
                            "required"
                          )}
                        </div>

                        <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6">
                          <label htmlFor="eventCategory" className="fs-md-18">
                            Post Code:
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            id="postCode"
                            autoComplete="off"
                            value={values.postCode}
                            onChange={(e) => {
                              const inputValue = handleNumericInput(
                                e.target.value
                              );
                              if (
                                inputValue !== null &&
                                inputValue.length < 8
                              ) {
                                setValues({
                                  ...values,
                                  postCode: e.target.value,
                                });
                              }
                            }}
                          />
                          {validator.current.message(
                            "post code",
                            values.postCode,
                            "required"
                          )}
                        </div>
                      </div>

                      <div className="row mb-2">
                        <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6">
                          <label htmlFor="phoneNumber" className="fs-md-18">
                            Phone Number:
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            id="phoneNumber"
                            name="phoneNumber"
                            autoComplete="off"
                            value={values.phoneNumber}
                            onChange={(e) => handleChangeValue(e)}
                          />
                        </div>
                      </div>
                    </div>

                    <hr className="divider my-5" />

                    <div className="btn-wrapper text-center mt-4 mb-2 pt-2">
                      <button
                        type="submit"
                        className="btn btn-custom"
                        disabled={loading || false}
                      >
                        {loading
                          ? "Loading..."
                          : details?.id
                          ? "Update"
                          : "Submit"}
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default EventsAdd;
