import React, { useState } from "react";
import { useSelector } from "react-redux";
import ActivitiesDashboard from "./activities/ActivitiesDashboard";
import NightLifeDashboard from "./nightLife/NightLifeDashboard";
import RestaurantDashboard from "./restaurant/RestaurantDashboard";
import HotelDashboard from "./users/HotelDashboard";

const DashboardAction = () => {
  const { current } = useSelector((state) => state.user);
  const [userRole] = useState(current?.user_type);
  
  const renderData = () => {
    switch (userRole) {
      case "Activity":
        return <ActivitiesDashboard />;
      case "Nightlife":
        return <NightLifeDashboard />;
      case "Restaurant":
        return <RestaurantDashboard />;
      case "Hotel":
        return <HotelDashboard />;
      default:
        return <RestaurantDashboard />;
    }
  };

  return (
    <>
      {console.log("current", current)}
      {renderData()}
    </>
  );
};

export default DashboardAction;
