import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { useSelector, useDispatch } from "react-redux";
import { QuestionCircle } from "react-bootstrap-icons";
import { setUser } from "../../../redux/actions/user.actions";
import webServices from "../../../services/webServices";
import AppConstants from "../../../appConstants";
import { authCall } from "../../../services";
import VideoTutorialPopUp from "../VideoTutorialPopUp";

const ActivityCompanyTypeVideo = AppConstants.tutorial.activityCompanyType;

function ActivityCompanyType({ navigate }) {
  const dispatch = useDispatch();
  const { current } = useSelector((state) => state.user);
  const [loading, setLoading] = useState(false);
  const [selectedOption, setSelectedOption] = useState("");
  const [calendarType, setCalendarType] = useState("");
  const [videoURL, setVideoURL] = useState("");

  useEffect(() => {
    async function fetchData() {
      const resp = await authCall("getActivityBookingCalendar", {
        userId: current?.id,
      });
      if (resp?.type === "success") {
        setCalendarType(resp?.payload?.booking_calendar);
      }
    }
    fetchData();
  }, [current]);

  const handleOptionChange = (event) => {
    setSelectedOption(event.target.value);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (selectedOption === "") {
      toast.error("Please select an option.");
    } else {
      setLoading(true);
      const resp = await webServices.createTour({
        userId: current?.id,
        companyType: selectedOption,
        profileLavel: 3,
        bookingCalendar: calendarType,
      });
      if (resp?.type === "success") {
        setLoading(false);
        const resp2 = await webServices.updateDetails(current?.id, {
          companyId: resp?.payload?.id,
        });
        if (resp2?.type === "success") {
          dispatch(setUser(resp2?.payload));
          navigate(AppConstants.routes.admin.actiityCreateCompany);
        }
      } else {
        setLoading(false);
      }
    }
  };

  const handleClickTutorial = () => {
    setVideoURL(ActivityCompanyTypeVideo);
  };

  const tutoralClose = () => {
    setVideoURL("");
  };

  return (
    <>
      <div className="company-type-page py-4">
        <div className="container">
          <div className="row">
            <div className="col-sm-12 col-lg-12">
              <div className="common-info-card">
                <div className="heading-title d-flex flex-wrap justify-content-between gap-2">
                  <span>What type of company do you run?</span>
                  <span
                    className="cursor-pointer"
                    onClick={handleClickTutorial}
                  >
                    <QuestionCircle />
                  </span>
                </div>
                <div className="details-wrapper">
                  <div className="sub-title">
                    This help us tailer your profile for you
                  </div>
                  <form>
                    <div className="form-group d-flex align-items-center gap-3 mt-4">
                      <input
                        type="radio"
                        className="custom-radio m-0 companyType cursor-pointer"
                        id="compType1"
                        value="OneOffActivity"
                        checked={selectedOption === "OneOffActivity"}
                        onChange={handleOptionChange}
                      />
                      <label
                        className="form-check-label m-0"
                        htmlFor="compType1"
                      >
                        Activity
                      </label>
                    </div>
                    {/* this is comment out on 2025-02-18  due to no dropdown linking between variations */}
                    <div className="form-group d-flex align-items-center gap-3 mt-4 d-none">
                      <input
                        type="radio"
                        className="custom-radio m-0 companyType cursor-pointer"
                        name="companyType"
                        id="compType2"
                        value="MultipleVariations"
                        checked={selectedOption === "MultipleVariations"}
                        onChange={handleOptionChange}
                      />
                      <label
                        className="form-check-label m-0"
                        htmlFor="compType2"
                      >
                        Activity With Multiple Variations{" "}
                        <i>(For example Escape Room)</i>
                      </label>
                    </div>
                    {/* this is comment out on 2025-02-18 */}
                    <div className="form-group d-flex align-items-center gap-3 mt-4">
                      <input
                        type="radio"
                        className="custom-radio m-0 companyType cursor-pointer"
                        name="companyType"
                        id="compType3"
                        value="Tour"
                        checked={selectedOption === "Tour"}
                        onChange={handleOptionChange}
                      />
                      <label
                        className="form-check-label m-0"
                        htmlFor="compType3"
                      >
                        Tour{" "}
                        <i>
                          (An event where you travel from Point A to Point B)
                        </i>
                      </label>
                    </div>
                    <div className="btn-wrapper text-center pt-2">
                      <button
                        type="submit"
                        className="btn btn-custom"
                        onClick={handleSubmit}
                        disabled={loading || false}
                      >
                        {loading ? "Loading..." : "Submit"}
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <VideoTutorialPopUp videoURL={videoURL} closeVideo={tutoralClose} />
    </>
  );
}

export default ActivityCompanyType;
