import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import Select from "react-select";
import { useNavigate, useLocation } from "react-router-dom";
import { Badge, Row, Col, Button } from "react-bootstrap";
import { Trash, Plus, PencilSquare } from "react-bootstrap-icons";
import AdminSidebar from "../../layouts/AdminSidebar";
import { authCall } from "../../../services";
import AppConstants from "../../../appConstants";
import webServices from "../../../services/webServices";
import { handleNumericInput } from "../../util/utils";

const USERTYPE = [
  { value: 1, label: "Activity" },
  { value: 2, label: "Restaurant" },
  { value: 3, label: "Bar" },
  { value: 4, label: "Licensee" },
  { value: 5, label: "Promoter" },
  { value: 6, label: "Advertiser" },
  { value: 7, label: "Designer" },
  { value: 8, label: "Hotel" },
];

const SHOWCOMMISION = ["Activity", "Licensee", "Promoter"];
const USERS = ["Licensee", "Promoter", "Advertiser", "Designer", "Hotel"];

function UserListing() {
  const navigate = useNavigate();
  const location = useLocation();
  const { type } = location?.state || {};
  const [machinesList, setMachinesList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [loadingStatus, setLoadingStatus] = useState("");
  const [filterUserList, setFilterUserList] = useState([]);
  const [userList, serList] = useState([]);
  const [selectedOption, setSelectedOption] = useState({
    value: type ? type : "Activity",
    label: type ? type : "Activity",
  });

  const getUserList = async (type, status = false) => {
    if (!status) {
      setLoading(true);
    }

    const resp = await authCall("getUserList", {
      userType: type,
    });
    if (resp?.type === "success") {
      setFilterUserList(resp?.payload);
      serList(resp?.payload);
      setLoading(false);
    }
  };

  useEffect(() => {
    getUserList(selectedOption?.label ? selectedOption?.label : "Activity");
  }, []);

  // to change status
  const handleChangeStatus = async (id, userid, status) => {
    setLoadingStatus(`loading${id}`);
    const resp = await webServices.updateDetails(userid, { status });
    if (resp?.type === "success") {
      const updatedItems = userList
        .map((item) => (item.id === id ? { ...item, status: status } : item))
        .sort((a, b) => a.status.localeCompare(b.status));

      setFilterUserList(updatedItems);

      setLoadingStatus("");
    }
  };

  const handleDelete = (el) => {
    toast.warning(
      <div>
        <p>Are you sure you want to delete?</p>
        <Button
          variant="success"
          className="btn-sm"
          onClick={() => deleteItem(el)}
        >
          Yes
        </Button>
        <Button
          variant="danger"
          className="btn-sm"
          style={{ marginLeft: "2px" }}
          onClick={closeToast}
        >
          No
        </Button>
      </div>,
      {
        position: "top-center",
        autoClose: false,
        closeOnClick: false,
        draggable: false,
        closeButton: false,
      }
    );
  };

  const deleteItem = async (el) => {
    const resp = await authCall("deleteMachine", {
      machineId: el.id,
    });
    if (resp?.type === "success") {
      closeToast();
      const updatedItems = machinesList.filter((item) => item.id !== el.id);
      setMachinesList(updatedItems);
      toast.success("Deleted successfully.");
    }
  };

  const closeToast = () => {
    toast.dismiss();
  };

  const handleAdd = () => {
    navigate(AppConstants.routes.admin.userAdd, {
      state: { type: selectedOption?.label },
    });
  };

  const handleEdit = (details) => {
    navigate(AppConstants.routes.admin.userUpdate, { state: { details } });
  };

  const handleChangeUserType = (selected) => {
    setSelectedOption(selected);
    getUserList(selected.label);
  };

  const handleSearchFilter = (val) => {
    if (val) {
      const filteredData = userList.filter(
        (item) =>
          item?.company_name &&
          item.company_name.toLowerCase().includes(val.toLowerCase())
      );
      setFilterUserList(filteredData);
    } else {
      setFilterUserList(userList);
    }
  };

  // add commisions to bookign and users
  const handleAddCommission = (id, val) => {
    const inputValue = handleNumericInput(val);
    if (inputValue !== null && inputValue.length < 4) {
      setFilterUserList(
        filterUserList.map((field) =>
          field.id === id ? { ...field, commission: val } : field
        )
      );
    }
  };
  // save in database
  const handleBlur = async (id, val) => {
    if (val > 0 && selectedOption?.label === "Activity") {
      await authCall("tour", {
        bookingId: id,
        commission: val,
      });
    } else if (
      val > 0 &&
      (selectedOption?.label === "Licensee" ||
        selectedOption?.label === "Promoter")
    ) {
      await webServices.updateDetails(id, { commission: val });
    }
  };

  return (
    <>
      <div className="update-machine-page common-table-design py-4">
        <div className="container custom-container">
          <div className="row">
            <div className="col-sm-4 col-lg-3 mb-4">
              <AdminSidebar />
            </div>
            <div className="col-sm-8 col-lg-9">
              <div className="common-info-card">
                <div className="heading-title">Users</div>
                <div className="details-wrapper py-lg-4">
                  <Row className="align-items-center mb-4">
                    <Col xs={4}>
                      <>
                        <span className="fs-sm">Filter by User Type:</span>
                        <Select
                          className="text-body mt-1"
                          placeholder="Select Type"
                          value={selectedOption}
                          onChange={handleChangeUserType}
                          options={USERTYPE}
                        />
                      </>
                    </Col>
                    {!USERS.includes(selectedOption?.label) && (
                      <Col xs={3} className="text-end"></Col>
                    )}
                    <Col xs={5} className="text-end1">
                      <span className="fs-sm">Search by Company Name:</span>
                      <input
                        type="text"
                        className="form-control mt-1"
                        placeholder="Search..."
                        autoComplete="off"
                        onChange={(e) => handleSearchFilter(e.target.value)}
                      />
                    </Col>
                    {USERS.includes(selectedOption?.label) && (
                      <Col xs={3} className="text-end">
                        <Button
                          className="btn btn-custom btn-custom-sm fs-16 mt-4"
                          onClick={handleAdd}
                        >
                          <Plus />
                          New User
                        </Button>
                      </Col>
                    )}
                  </Row>
                  <div className="table-responsive">
                    <table className="table table-borderless table-custom m-0">
                      <thead>
                        <tr>
                          <th scope="col">Id:</th>
                          {selectedOption?.label === "Activity" && (
                            <th scope="col">Activity Name:</th>
                          )}
                          {selectedOption?.label === "Bar" && (
                            <th scope="col">Nightlife Name:</th>
                          )}
                          {selectedOption?.label === "Restaurant" && (
                            <th scope="col">Restaurant Name:</th>
                          )}
                          {USERS.includes(selectedOption?.label) && (
                            <th scope="col">Company Name:</th>
                          )}
                          <th scope="col">City:</th>
                          <th scope="col">Name:</th>
                          <th scope="col">Phone:</th>
                          <th scope="col">Email:</th>
                          {SHOWCOMMISION.includes(selectedOption?.label) && (
                            <th scope="col">Commission:</th>
                          )}
                          <th scope="col">Action:</th>
                        </tr>
                      </thead>
                      <tbody>
                        {!loading &&
                          (filterUserList.length > 0 ? (
                            filterUserList.map((el, index) => (
                              <tr key={index}>
                                <td scope="row">#{el.id}</td>
                                <td>{el.company_name}</td>
                                <td>{el?.location?.city}</td>
                                <td>{el.user_name}</td>
                                <td>{el.mobile_number}</td>
                                <td>{el.email}</td>
                                {SHOWCOMMISION.includes(
                                  selectedOption?.label
                                ) && (
                                  <td>
                                    <div className="input-wrapper-comm">
                                      <input
                                        type="text"
                                        className="form-control text-input-comm"
                                        value={el?.commission}
                                        onChange={(e) =>
                                          handleAddCommission(
                                            el.id,
                                            e.target.value
                                          )
                                        }
                                        onBlur={(e) =>
                                          handleBlur(el.id, e.target.value)
                                        }
                                      />
                                      <span className="postfix-comm">%</span>
                                    </div>
                                  </td>
                                )}

                                <td>
                                  <div className="icon-container">
                                    <Badge
                                      bg={
                                        el.status === "1" ? "success" : "danger"
                                      }
                                      className="cursor-pointer"
                                      onClick={() =>
                                        handleChangeStatus(
                                          el.id,
                                          el.userId,
                                          el.status === "1" ? "2" : "1"
                                        )
                                      }
                                      title={
                                        el.status === "1"
                                          ? "Click to Block"
                                          : "Click to Active"
                                      }
                                    >
                                      {loadingStatus === `loading${el.id}`
                                        ? "Loading..."
                                        : el.status === "1"
                                        ? "Active"
                                        : "Blocked"}
                                    </Badge>
                                    <PencilSquare
                                      onClick={() => handleEdit(el)}
                                      className="cursor-pointer"
                                      style={{
                                        color: "#ddb12e",
                                      }}
                                    />
                                    <Trash
                                      onClick={() => handleDelete(el)}
                                      className="cursor-pointer"
                                      style={{
                                        color: "red",
                                      }}
                                    />
                                  </div>
                                </td>
                              </tr>
                            ))
                          ) : (
                            <tr>
                              <td colSpan={10} className="text-center">
                                No record found
                              </td>
                            </tr>
                          ))}
                        {loading && (
                          <tr>
                            <td colSpan={10} className="text-center">
                              Loading...
                            </td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default UserListing;
