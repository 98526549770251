import React, { useEffect, useState } from "react";
import AdminSidebar from "../../../layouts/AdminSidebar";
import { toast } from "react-toastify";
import { useLocation, useNavigate } from "react-router-dom";
import webServices from "../../../../services/webServices";
import AppConstants from "../../../../appConstants";
import ActivityDetailsPage from "../../../common/ActivityDetailsPage";
import PageLoader from "../../../util/PageLoader";

function UpdateAdminCompanyDetails() {
  const navigate = useNavigate();
  const location = useLocation();
  const { activityId } = location.state || {};
  const [loading, setLoading] = useState(false);
  const [companyDetails, setCompanyDetails] = useState({});

  useEffect(() => {
    if (!activityId) {
      navigate(AppConstants.routes.admin.activityEditListing);
    }

    async function fetchData() {
      const resp = await webServices.getTour(activityId);
      if (resp.type === "success") {
        setCompanyDetails(resp?.payload);
      }
    }
    fetchData();
  }, [activityId]);

  // if (!activityId) {
  //   return null;
  // }

  const handleSubmit = async (data, _) => {
    setLoading(true);
    const resp = await webServices.createTour(data);
    if (resp.type === "success") {
      setLoading(false);
      toast.success(`Updated successfully`);
      navigate(AppConstants.routes.admin.activityList);
    } else {
      setLoading(false);
    }
  };

  return (
    <>
      {companyDetails?.activity_code ? (
        <ActivityDetailsPage
          SideBar={AdminSidebar}
          details={companyDetails}
          btnLabel="Update"
          handleSubmit={handleSubmit}
          loading={loading}
        />
      ) : (
        <PageLoader />
      )}
    </>
  );
}

export default UpdateAdminCompanyDetails;
